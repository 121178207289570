import React from 'react';
import { InlineWidget } from 'react-calendly';

import ZoomInfoHeaderWrapper from '../Header/zoominfoHeader.style';

import bg from '../../../../../common/assets/image/saasModern/zoominfo/bg.jpg';

const ZoomInfoHeader = () => {
  return (
    <ZoomInfoHeaderWrapper avatar={bg}>
      <div className="text">
        <h1>Eazyleads</h1>
        <h1>Alternative?</h1>
        <h3>
          Better Platform - <span className="blue">Fraction of Cost</span>
        </h3>
        <h4>
          Over <span className="blue">600M</span> Verified B2B Contacts &{' '}
          <span className="blue">120M Direct Dials</span>
        </h4>
        <p>
          <h3>
            Only{' '}
            <h2 style={{ display: 'inline', fontStyle: 'italic' }}>
              $49/Month...
            </h2>
          </h3>
        </p>
      </div>
      <InlineWidget
        url="https://calendly.com/cloduraai/bookademo?hide_event_type_details=1&hide_gdpr_banner=1%22"
        styles={{ width: '450px', height: '650px' }}
      />
    </ZoomInfoHeaderWrapper>
  );
};

export default ZoomInfoHeader;
